<template>
  <div>
    <CRow>
      <CCol lg="6">
        <CCard>
          <CCardHeader>
            <strong>Редактировать ГРС</strong>
          </CCardHeader>
          <CCardBody>
            <pre></pre>



            <CForm @submit.prevent="update()">
              <CInput
                      type="hidden"
                      name="action"
                      v-model="form.action"
                      required
              />
              <CInput
                      label="Введите название ГРС"
                      v-model="form.grs_name"
                      required
              />

                <label>Выберите Область</label>
                <model-list-select :list="regionDatas"
                                   v-model="form.region_id"
                                   option-value="value"
                                   option-text="label"
                                   placeholder="Выберите область">
                </model-list-select>

                <br>

                <label>Выберите Оператора</label>
                <model-list-select :list="operatorDatas"
                                   v-model="form.operator_id"
                                   option-value="value"
                                   option-text="label"
                                   placeholder="select item">
                </model-list-select>

                <br>

              <CButton type="submit" size="sm" color="primary"><CIcon name="cil-check-circle"/> Обновить</CButton>
            </CForm>
          </CCardBody>
          <CCardFooter>
          </CCardFooter>
        </CCard>
      </CCol>

    </CRow>
  </div>
</template>

<script>
  import axios from 'axios'
  import { ModelListSelect } from 'vue-search-select'
  import 'vue-search-select/dist/VueSearchSelect.css'

  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  export default {
    name: 'Grs',
    data: function() {
      return {
        activeGrsId: 0,
        apiDatas: [],
        grsDatas: [],
        regionDatas: [],
        operatorDatas: [],
        form: {
          action: 'update_grs',
          id: "",
          grs_name: "",
          region_id: "",
          operator_id: ""
        },
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler (route) {
          if (route.query && route.query.page) {
            this.activePage = Number(route.query.page)
          }
        }
      }
    },
    methods: {
      update () {

        let bodyFormData = new FormData();

        bodyFormData.append('action', "update_grs");
        bodyFormData.append('id', this.activeGrsId);
        bodyFormData.append('name', this.form.grs_name);
        bodyFormData.append('region_id', this.form.region_id);
        bodyFormData.append('operator_id', this.form.operator_id);

        axios({
          method: "post",
          url: `${axios.defaults.baseURL}`,
          data: bodyFormData
        })
          .then((r) => {
            this.$router.push({path: `/grses`})
            //Perform Success Action
            // this.getDataFromApi()
          })
          .catch((error) => {
            console.error(error)
            // error.response.status Check status code
          }).finally(() => {
          // this.getDataFromApi()
        });
      },
      // set () {
      //
      //   // console.log(31232132131)
      //
      //   let bodyFormData = new FormData();
      //
      //   bodyFormData.append('action', "set_grs");
      //   bodyFormData.append('name', "");
      //   bodyFormData.append('grs_name', "");
      //   bodyFormData.append('line_order', "");
      //   os.forEach((el)=>{
      //
      //     bodyFormData.append('name', el.name);
      //     bodyFormData.append('grs_name', el.grs_name);
      //     bodyFormData.append('line_order', el.line_order);
      //
      //     axios({
      //       method: "post",
      //       url: "${axios.defaults.baseURL}",
      //       data: bodyFormData
      //     })
      //       .then((r) => {
      //         console.log(r.data)
      //         // this.$router.push({path: `/grses`})
      //         //Perform Success Action
      //         // this.getDataFromApi()
      //       })
      //       .catch((error) => {
      //         console.error(error)
      //         // error.response.status Check status code
      //       }).finally(() => {
      //       // this.getDataFromApi()
      //     });
      //   })
      //
      // },
      pageChange (val) {
        this.$router.push({ query: { page: val }})
      }
    },
    created() {
      this.$watch(
        () => this.$route.params
      )
    },
    mounted() {
      if (this.$route.meta.gid === undefined) {
        this.activeGrsId = getCookie('activeGrsId');
      } else {
        document.cookie = `activeGrsId=${this.$route.meta.gid}`
        this.activeGrsId = this.$route.meta.gid;
      }

      axios
        .get(`${axios.defaults.baseURL}?action=get_grs&id=${this.activeGrsId}`)
        .then((response) => {
          // console.log(response.data)
          this.form.grs_name = response.data[0].name
          this.form.region_id = response.data[0].region_id
          this.form.operator_id = response.data[0].operator_id
        })
      axios
        .get(`${axios.defaults.baseURL}?action=get_regions`)
        .then((response) => {
          response.data.map((el) => {
            this.regionDatas.push({
              value: el.id,
              label: el.name
            })
          });
        })
      axios
        .get(`${axios.defaults.baseURL}?action=get_operators`)
        .then((response) => {
          response.data.map((el) => {
            this.operatorDatas.push({
              value: el.id,
              label: el.name
            })
          });
        })
    },
    components: {
      ModelListSelect
    }
  }
</script>
